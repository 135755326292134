<template>

    <Row cmpt="SpacerTinyRow" :showBorder="showBorder">
      <Column class="rc-text-xtiny rc-text-label" :class="alignment()">
        <br />
      </Column>
    </Row>

</template>

<script>

import { mapGetters } from 'vuex'

import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'spacer-tiny-row',
  components: {
    Row,
    Column,
  },
  props: {
    align: { type: String, default: "centre" },
    cmpt: { type: String, default: "label" },
    showBorder: { type: Boolean, default: false },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
  },
  methods: {
    alignment: function() {
      if (this.align === "left") {
        return "rc-text-left";
      }
      if (this.align === "right") {
        return "rc-text-right";
      }
      return "rc-text-centre";
    }
  },
}
</script>
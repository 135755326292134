<template>
  <Box>

    <SavingDialog 
          v-show="showSavingDialog" 
          @close="closeSavingDialog" />
          
    <Row :showBorder="false">
      <Column :width="1" />
      <Column :width="13">
        <Box>
          <TitleRow> Invite User </TitleRow>
          <SpacerTinyRow />
          <div v-if="isAdmin">Admin</div>
          <ErrorRow :error="error" />
          <SuccessRow :message="message" />
          
          <TitleRow :subtitle="true" align="left">
            First and last name
          </TitleRow>

          <ValueRow align="left">
            {{ inviteTitle }}
          </ValueRow>

          <Row>
            <Column :width="5">
              <Box>
                <KeyValue :title="FIELDS.FIRST" placeholder="First Name" :mandatory="true"
                  :required="isValueRequired(FIELDS.FIRST)" v-on:value="setValue" />
              </Box>
            </Column>
            <Column :width="5">
              <Box>
                <KeyValue :title="FIELDS.LAST" placeholder="Last Name" :mandatory="true"
                  :required="isValueRequired(FIELDS.LAST)" v-on:value="setValue" />
              </Box>
            </Column>
            <Column :width="5">
              <Box>
                <KeyValue :title="FIELDS.PHONE" placeholder="Phone number (AAA-BBB-CCCC)"
                 v-on:value="setValue" />
              </Box>
            </Column>
          </Row>
              
          <TitleRow :subtitle="true" align="left">
            Email
          </TitleRow>
          <ErrorRow :error="emailError" />
          <Row>
            <Column :width="8">
              <Box>
                <KeyValue :title="FIELDS.EMAIL" :mandatory="true" :required="isValueRequired(FIELDS.EMAIL)"
                  placeholder="Email" v-on:value="setValue" />
              </Box>
            </Column>
            <Column :width="7">
              <Box>
                <KeyValue :title="emailCheck" :mandatory="true" :required="isValueRequired('emailCheck')"
                  placeholder="Email Check" hint="Entering the email address twice helps elimitate typos"
                  v-on:value="checkValue" />
              </Box>
            </Column>
          </Row>
          <SpacerRow />

          <TitleRow :subtitle="true" align="left"> Drivers License </TitleRow>
          <Row>
            <Column :width="10">
              <Box>
                <KeyList :title="UserFields.LICENSE_CLASS" 
                  placeholder="License Class" 
                  :defaultValue="licenseClass"
                  hint="In the future the app will prevent drivers from operating a vehicle too heavy for the license class"
                  :options="licenseClassNames"
                  v-on:value="setValue" />
              </Box>
           </Column>
           <Column :width="5">
              <Box>
                <KeyDate 
                  :title="UserFields.LICENSE_EXPIRY" 
                  placeholder="Expiry Date" 
                  :defaultValue="licenseExpiry"
                  v-on:value="setValue" />
              </Box>
            </Column>
          </Row>

          <SpacerRow />

          <TitleRow :subtitle="true" align="left">
            Role, Employee ID
          </TitleRow>

          <KeyList title="UserRole" :options="roles" :defaultValue="defaultRole"
            hint="Every user must have a role, the driver role is the simplest role and lets them use only the app. The manager and owner roles will give them access to the portal as well as the app."
            placeholder="Role" v-on:value="setValue" />

          <KeyValue title="employmentId" hint="Employee ID is optional unless your organization shares tablets."
            placeholder="Employee ID" v-on:value="setValue" />

        </Box>
      </Column>
      <Column :width="1" />
    </Row>

    <SpacerRow />
    <Row>
      <Column :width="2" />
      <Column :width="4">
        <Box>
          <Button :go="true" v-on:click="add"> Save </Button>
          <SpacerRow />
          <Row>
            <Column :width="15">
            <div class="rc-font-small rc-text-value">
            <p><span style="background:green;color:white;border-radius:2px;padding:2px;">NEW!</span> 
            Normally an invite email is sent immediately. With this option you can manually add and save a user. When ready, invite the user later by using the <b>Send Invite</b> button on the Users page</p>
            </div>
            </Column>
          </Row>
        </Box>
      </Column>
      <Column :width="3" />
      <Column :width="4">
        <Box>
          <Button :go="true" v-on:click="invite">Save and Send Invite</Button>
        </Box>
      </Column>
      <Column :width="2" />
    </Row>
    <SpacerRow />

  </Box>
</template>

<script>
import { mapGetters } from 'vuex';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import CompanyUtils from '@/views/portal/companys/Utils.js';

import SuccessRow from '@/components/row/SuccessRow.vue';
import Button from "@/portals/shared/library/button/Button.vue";

import User from '@/domain/model/user/User.js';
import Employee from '@/domain/model/employee/Employee.js';
import ErrorRow from '@/components/row/ErrorRow.vue';
import KeyValue from '@/components/input/KeyValue.vue';
import KeyList from "@/components/input/KeyList.vue";
import KeyDate from '@/components/input/KeyDate.vue';

import SpacerRow from '@/portals/shared/library/spacer/SpacerRow.vue';
import SpacerTinyRow from '@/portals/shared/library/spacer/SpacerTinyRow.vue';
import TitleRow from '@/portals/shared/library/title/TitleRow.vue';
import Box from '@/portals/shared/library/box/Box.vue';
import Row from '@/portals/shared/library/box/Row.vue';
import Column from '@/portals/shared/library/box/Column.vue';
import ValueRow from '@/portals/shared/library/value/ValueRow.vue';
import SavingDialog from "@/portals/shared/views/saving/SavingDialog.vue";

export default {
  name: 'portal-employees-invite',
  components: {
    TitleRow, SpacerTinyRow,
    ErrorRow,
    KeyValue, Button, SavingDialog,
    KeyList, KeyDate,
    SuccessRow,
    SpacerRow, 
    ValueRow,
    Box, Row, Column,
  },
  props: {
    showButtons: { type: Boolean, default: false },
    // isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      message: null,
      users: [],
      kvps: {},
      id: function() {
        if (this.$route.params['id']) {
          return this.$route.params['id'];
        } else {
          return ContentUtils.baseDecode(this.$route.params.operatorId);
        }
      },
      isSaving: false,
      FIELDS: User.FIELDS,
      UserFields: User.FIELDS,
      EmployeeFields: Employee.FIELDS,
      missing: null,
      emailCheck: null,
      emailError: null,
      defaultRole: null,
      licenseClass: null,
      licenseExpiry: null,
      showSavingDialog: false,
      isAdmin: false,
    };
  },
  computed: {
    ...mapGetters([
      'auth_client',
      'auth_user',
      'auth_connected',
      'employees_found',
      'companys_found',
      'users_store',
      'roles_found',
      'domain',
    ]),
    company: function () {
      return this.companys_found.map[this.id];
    },
    companyObj: function() {
      return this.domain.companys().findById(this.id);
    },
    roles: function () {
      return ContentUtils.ROLES();
    },
    defaultRoleId: function () {
      return ContentUtils.ROLE_DRIVER()['@rid'];
    },
    inviteTitle: function () {
      return "Invite someone to join " + this.companyName(this.company);
    },
    licenseClassNames: function () {
      return ContentUtils.LICENSES();
    },
  },
  watch: {
    auth_connected() {
      if (this.auth_connected) {
        this.domain.logger().info("Connected!");
        this.send()
      }
    },
  },
  mounted: function () {
    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN });
    } else {
      this.defaultRole = this.defaultRoleId;
      this.setValue({
        key: 'UserRole',
        value: this.defaultRoleId,
      });
      this.setValue({
        key: 'inviteNow',
        value: true,
      });
    }
  },
  methods: {
    isEven: function (position) {
      return StringUtils.isEven(position);
    },
    isValueRequired: function (value) {
      return this.missing === value;
    },

    companyName: function (company) {
      return CompanyUtils.companyName(company);
    },

    companyId: function () {
      return CompanyUtils.companyId(this.company);
    },

    checkValue: function (kvp) {
      this.emailCheck = kvp.value;
    },
    setValue: function (kvp) {
      ContentUtils.set(this.kvps, kvp, this.domain.logger());
      this.error = null;
      this.message = null;
      this.missing = null;
    },

    add: function() {
      this.error = null;
      this.message = null;
      this.emailError = null;
      if (!this.requirementsMet()) {
        return;
      }
      this.setValue({
        key: 'inviteNow',
        value: false,
      });
      this.isSaving = true;
      if (this.auth_connected) {
        this.send();
      } else {
        this.$connect();
      }
    },
    
    invite: function () {
      this.error = null;
      this.message = null;
      this.emailError = null;
      this.domain.logger().info("Checking fields all required fields need to be filled in");
      if (!this.requirementsMet()) {
        this.domain.logger().info("Check failed missing field");
        return;
      }
      this.isSaving = true;
      if (this.auth_connected) {
        this.domain.logger().info("Already Connected");
        this.send();
      } else {
        this.domain.logger().info("No connection, reconnecting");
        this.$connect();
      }
    },

    send: function () {
      this.domain.logger().info("Sending");
      this.showSavingDialog = true;
      this.kvps[ConstUtils.FIELDS.COMPANY] = this.companyId();
      var event = this.domain.events().userInvite(this.kvps);
      var inviteNow = this.kvps["inviteNow"];
      const context = {
        status: inviteNow ? "invited" : "notinvited",
      };
      this.domain.logger().info("Event created sending: {0}", [JSON.stringify(event.data, null, 2)]);
      event.send(this.listener, context);
    },
    
    listener: function (rcEvent, context) {
      this.showSavingDialog = false;
      if (rcEvent.error()) {
        this.error = rcEvent.error();
      } else {
        const query = {
          status: context["status"],
        };
        var roles = this.domain.session().employee().find().roles().find();
        if (roles.isAdministrator()) {
          const company = this.domain.session().company();
          const path = StringUtils.format(ConstUtils.ROUTES.EMPLOYEE.LIST_PATH_ADMIN, [ContentUtils.encodeForUrl(company.id())]);
          this.$router.push({ path });
        } else {
          const path = StringUtils.format(ConstUtils.ROUTES.EMPLOYEE.LIST_PATH, [this.$route.params.operatorId]);
          this.$router.push({ path, query });
        }
      }
      this.isSaving = false;
    },
    
    requirementsMet: function () {
      this.missing = null;
      var met = ContentUtils.hasValue(this.kvps, this.FIELDS.FIRST);
      if (!met) {
        this.missing = this.FIELDS.FIRST;
        this.domain.logger().info("First name not filled in: {0}", [this.missing]);
        return met;
      }
      met = ContentUtils.hasValue(this.kvps, this.FIELDS.LAST);
      if (!met) {
        this.missing = this.FIELDS.LAST;
        this.domain.logger().info("Last name not filled in: {0}", [this.missing]);
        return met;
      }
      met = ContentUtils.hasValue(this.kvps, this.FIELDS.EMAIL);
      if (!met) {
        this.missing = this.FIELDS.EMAIL;
        this.domain.logger().info("Email name not filled in: {0}", [this.missing]);
        return met;
      }
      met = this.emailCheck != null;
      if (!met) {
        this.missing = 'emailCheck';
        this.domain.logger().info("EmailCheck name not filled in: {0}", [this.missing]);
        return met;
      }
      if (met) {
        var userEmail = this.kvps[this.FIELDS.EMAIL];
        if (!StringUtils.isEmail(userEmail)) {
          this.domain.logger().info("User email not right: {0}", [this.userEmail]);
          this.emailError = {
              messageId: "InvalidEmailId",
              hint: "The email address provided is not valid"
          }
          this.missing = this.FIELDS.EMAIL;
          return false;
        }
        if (this.emailCheck != userEmail) {
          this.emailError = {
            messageId: "emailDoesNotMatch",
            hint: "Email addresses must match"
          }
          this.missing = this.FIELDS.EMAIL;
          this.domain.logger().info("User email dont match: {0} {1}", [this.userEmail, this.emailCheck]);
          return false;
        }
      }
      this.domain.logger().info("Requirements met?: {0}", [met]);
      return met;
    },
    
    closeSavingDialog: function() {
      this.showSavingDialog = false;  
    },
  }
}
</script>